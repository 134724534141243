
import { defineComponent, ref } from "vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";


export default defineComponent({
  components: {
    Nav,
    Footer,
  }
})
